import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { useFormik } from 'formik'; 
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';
import Quagga from 'quagga';
import { MultiSelect } from 'primereact/multiselect';
import "../../assets/css/styles.css";
import { InputSwitch } from 'primereact/inputswitch';
import { DateTime } from "luxon";

export default function TrainingRecordAdd(training){

    const navigate = useNavigate();

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [fileList, setFileList] = useState([]);
    const [saveAfterClose,setSaveAfterClose] = useState(false);
    const [showBarScanner, setShowBarScanner] = useState(false);

    const [scanValue,setScanValue] = useState("");
    const [scanTitle,setScanTitle] = useState("");

    const [formTraining,setFormTraining] = useState(training);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState(state.training_types);

    const [withExpiration,setWithExpiration] = useState(false);
    const [fromInvitation,setFromInvitation] = useState(false);
    const [selectWithExpiration,setSelectWithExpiration] = useState("");
    const [selectFromInvitation,setSelectFromInvitation] = useState("");

    const fileListRef = useRef(null);
    const submitRef = useRef(null);
    

    const validate = values => {
        const errors = {};
        if(!values.training_id){
            errors.training_id = '*Povinné';
        }
        else{
            let my_employees = values.employees.map(employee => employee.id);
            errors.employees="";
            for (var i = 0; i < my_employees.length; i++) {
                let card = state.training_cards.filter(emp => emp.id == my_employees[i]);
                if(card.length<1)errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá založenú kartu!\n';
                else{                
                    let training = card[0].training_card.filter(card => card.employee_id == my_employees[i] && card.training_id==values.training_id);
                    if(training.length<1)errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá evidované dané školenie!\n';
                }
            }
            if(errors.employees=="")delete errors.employees;
        }
        return errors;
    };

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };


    const formik = useFormik({
        initialValues: {
            training_id: '',last_training_date: '',employees:[],description:'',
        },
        enableReinitialize: true,
        validate,
        onSubmit: values => {
          setDialogText("Ukladám");
          setVisible(true);
          let formData = new FormData();
            for (let value in values) {
                if(value!="employees")formData.append(value, values[value] != null ? values[value] : "");
                else{
                    let my_employees = values.employees.map(employee => employee.id);
                    for (var i = 0; i < my_employees.length; i++) {
                        formData.append('employees[]', my_employees[i]);
                    }
                }
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            postRequest('/api/training_records', formData,true)
            .then((response) => {
                console.log(response);
                setFileList([]);
                fileListRef.current.value = null;
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                if(saveAfterClose)navigate("/traininghome");
                setVisible(false);
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
                setVisible(false);
            });
        },
    });

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        if(departmentId=="")setTrainings(state.training_types);
        setTrainings(state.training_types
        .filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    return(
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col pb-2 bg-white min-h-[calc(100vh_-_56px)]'>
        <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/traininghome")}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAfterClose(true);submitRef.current.click()}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        </div>
            <div className='w-auto px-4 mx-2'>
                <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>NOVÉ ŠKOLENIE</h1>
                <div className='w-full mb-2'>
                    <label htmlFor='trainingDepartment' className='font-semibold '>
                        Oddelenie školenia:
                        <select className='w-full h-10 border border-black rounded-md text-black'
                        name="trainingDepartment"
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        >
                            <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='training_id' className='font-semibold '>
                        Názov školenia:
                        <select required
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="training_id"
                        onChange={formik.handleChange} value={formik.values.training_id}
                        >
                            <option value="">Vyber školenie</option>
                          {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='last_training_date' className='font-semibold'>
                        Dátum školenia:
                        <input required
                        className='w-full min-h-10 text-left p-2 border border-black rounded-md [appearance:none]'
                        type='date'
                        name='last_training_date'
                        onChange={formik.handleChange} value={formik.values.last_training_date}
                        />
                    </label>
                </div>
                <div className='flex w-full mb-2 justify-between items-center'>
                    <label htmlFor='with_expiration' className='font-semibold '>
                        Zamestnanci s expiráciou:
                        
                    </label>
                    <InputSwitch name='with_expiration' checked={withExpiration} onChange={(e) => {setWithExpiration(e.value);setFromInvitation(false);}} />
                </div>
                {withExpiration && <div className='w-full mb-2'>
                        <select required
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="select_with_expiration"
                        onChange={(e) => setSelectWithExpiration(e.target.value)} value={selectWithExpiration}
                        >
                            <option value="">Vyber možnosť</option>
                            <option value="after">Po expirácii</option>
                            <option value="15-">15-</option>
                            <option value="30-">30-</option>
                            <option value="45-">45-</option>
                            <option value="60-">60-</option>
                        </select>
                </div>}
                <div className='flex w-full mb-2 justify-between items-center'>
                    <label htmlFor='from_invitation' className='font-semibold '>
                        Zamestnanci z pozvánky:
                        
                    </label>
                    <InputSwitch name='from_invitation' checked={fromInvitation} onChange={(e) => {setFromInvitation(e.value);setWithExpiration(false);}} />
                </div>
                {fromInvitation && <div className='w-full mb-2'>
                        <select required
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="select_from_invitation"
                        onChange={(e) => setSelectFromInvitation(e.target.value)} value={selectFromInvitation}
                        >
                            <option value="">Vyber pozvánku</option>
                            {state.training_invitations.map((invitation) => (
                            <option key={invitation.id} value={invitation.id}>{invitation.name + " - " + DateTime.fromISO(invitation.invitation_date).toFormat("dd.MM.yyyy")}</option>
                            ))}
                        </select>
                </div>}
                <div className='w-full mb-2'>
                    <label htmlFor='employees' className='font-semibold'>
                        Zamestnanci:
                        <MultiSelect required value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.employees.map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name" 
                        filter placeholder="Vyber používateľov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors.employees ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.employees}</div> : null}
                    </label>
                </div>
                <div>
                    <label className="block text-md my-2 font-semibold">POČET: {formik.values.employees.length}</label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='description' className='font-semibold'>
                        Poznámka:
                    <textarea name="description" className='w-full p-2 border border-black rounded-md'
                    onChange={formik.handleChange} value={formik.values.description}
                    >
                    </textarea>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='last_training_date' className='font-semibold'>
                        Protokol:
                        <input
                        accept='image/*,application/pdf,.msg'
                        name='files'
                        ref={fileListRef}
                        onChange={handleFileChange}
                        className='w-full p-2 border border-black rounded-md'
                        type='file'
                        multiple="multiple"
                        id='file'
                        />
                    </label>
                </div>
            </div>
        </div>
        <button type="submit" ref={submitRef} className='hidden'></button>
        </form>
        </>
    )
}