export const SaveIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Uložiť</title>
<path d="M4 3H18L20.7071 5.70711C20.8946 5.89464 21 6.149 21 6.41421V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM7 4V9H16V4H7ZM6 12V19H18V12H6ZM13 5H15V8H13V5Z" fill="#7f1d1d">
</path>
</svg>;}

export const SaveAndCloseIcon = () => {return <svg focusable="false" 
iconid="icon-save-and-close-2" viewBox="0 0 20 20"
width="24" height="24">
<title id="title">Uložiť a zavrieť</title>
<path fill="#7f1d1d" fillRule="evenodd" clipRule="evenodd" d="M4.27734,6.3335h3.88965c0.21387,0,0.38867-0.1748,0.38867-0.38916V4.38867
C8.55566,4.17334,8.38086,4,8.16699,4H4.27734C4.06348,4,3.88867,4.17334,3.88867,4.38867v1.55566
C3.88867,6.15869,4.06348,6.3335,4.27734,6.3335L4.27734,6.3335z M10.11133,14.10254c0-0.4248-0.34473-0.76953-0.77051-0.76953
H4.65918c-0.42578,0-0.77051,0.34473-0.77051,0.76953v0.0166c0,0.42383,0.34473,0.76953,0.77051,0.76953h4.68164
c0.42578,0,0.77051-0.3457,0.77051-0.76953V14.10254z M10.11133,11.76953C10.11133,11.34375,9.7666,11,9.34082,11H4.65918
c-0.42578,0-0.77051,0.34375-0.77051,0.76953v0.01563c0,0.4248,0.34473,0.77051,0.77051,0.77051h4.68164
c0.42578,0,0.77051-0.3457,0.77051-0.77051V11.76953z M12.44434,16.05566c0,0.21289-0.1748,0.38867-0.38867,0.38867H1.94434
c-0.21387,0-0.38867-0.17578-0.38867-0.38867V9.8335c0-0.21582,0.1748-0.38916,0.38867-0.38916h10.11133
c0.21387,0,0.38867,0.17334,0.38867,0.38916V16.05566z M14,9v7.05566C14,17.12891,13.12891,18,12.05566,18H1.94434
C0.87109,18,0,17.12891,0,16.05566V5.9458C0,4.87061,0.87109,4,1.94434,4h0.13379C2.05664,4.12451,2,4.25537,2,4.38867
C2,4.77783,2,5.9458,2,5.9458C2,7.01709,3.28223,8,4.35645,8h3.81055C8.60156,8,9,7.74121,9,7.5v0.38867
C9,8.31592,9.68359,9,10.1123,9H14z"></path>
<path fill="#7f1d1d" fillRule="evenodd" clipRule="evenodd" d="M17.70605,0.29297c-0.39063-0.39063-1.02246-0.39063-1.41406,0l-1.79297,1.79297
l-1.79297-1.79297c-0.39063-0.39063-1.02246-0.39063-1.41406,0c-0.39063,0.39111-0.39063,1.02344,0,1.41406L13.08496,3.5
l-1.79297,1.79297c-0.39063,0.39063-0.39063,1.02344,0,1.41406C11.4873,6.90234,11.74316,7,11.99902,7
s0.5127-0.09766,0.70703-0.29297l1.79297-1.79297l1.79297,1.79297C16.4873,6.90234,16.74316,7,16.99902,7
s0.5127-0.09766,0.70703-0.29297c0.3916-0.39063,0.3916-1.02344,0-1.41406L15.91309,3.5l1.79297-1.79297
C18.09766,1.31641,18.09766,0.68408,17.70605,0.29297"></path>
</svg>;}

export const AddIcon = () => {return  <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Pridať nový</title>
<path d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 11V8H13V11H16V13H13V16H11V13H8V11H11Z" fill="#7f1d1d">
</path>
</svg>;}

export const EditIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Upraviť</title>
<path d="M21 6.75736L19 8.75736V4H10V9H5V20H19V17.2426L21 15.2426V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9.00319 2H19.9978C20.5513 2 21 2.45531 21 2.9918V6.75736ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761Z" fill="#7f1d1d">
</path>
</svg>;}

export const CopyIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Kopírovať</title>
<path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z" fill="#7f1d1d">
</path></svg>;}

export const DeleteIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Vymazať</title>
<path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="#7f1d1d">
</path></svg>;}

export const DeleteIconNtfs = () => {return <svg 
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 24 24"
  width="24" height="24" >
  <title id="title">Vymazať</title>
  <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="#fff">
  </path></svg>;}

export const StepBackIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Krok späť</title>
<path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const StepForwardIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Krok vpred</title>
<path d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const PrintIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Tlačiť</title>
<path d="M17 2C17.5523 2 18 2.44772 18 3V7H21C21.5523 7 22 7.44772 22 8V18C22 18.5523 21.5523 19 21 19H18V21C18 21.5523 17.5523 22 17 22H7C6.44772 22 6 21.5523 6 21V19H3C2.44772 19 2 18.5523 2 18V8C2 7.44772 2.44772 7 3 7H6V3C6 2.44772 6.44772 2 7 2H17ZM16 17H8V20H16V17ZM20 9H4V17H6V16C6 15.4477 6.44772 15 7 15H17C17.5523 15 18 15.4477 18 16V17H20V9ZM8 10V12H5V10H8ZM16 4H8V7H16V4Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const SearchIcon = ({color}) => {
  if(color==="active"){
    return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prehľadávať</title>
<path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="#14532d">
</path></svg>;
  }
  else {
    return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prehľadávať</title>
<path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="#7f1d1d">
</path></svg>;
  }
}

export const SearchIconPrio = () => {
  return <svg 
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 20 20"
  width="30" height="30" >
  <title id="title">Prehľadávať</title>
  <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="#fff">
  </path></svg>;
}

export const ExportIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Exportovať</title>
<path d="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM4 4.73481V19.2656L14 20.6942V3.30624L4 4.73481ZM17 19.0002H20V5.00022H17V3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V19.0002ZM10.2 12.0002L13 16.0002H10.6L9 13.7145L7.39999 16.0002H5L7.8 12.0002L5 8.00022H7.39999L9 10.2859L10.6 8.00022H13L10.2 12.0002Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const VerticalLineIcon = () => {return <svg 
width="24" height="24" 
viewBox="0 0 24 24" 
fill="none" 
xmlns="http://www.w3.org/2000/svg"> 
<path d="M13 2H11V22H13V2Z" fill="grey"/> 
</svg>;}

export const BackToFirstIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prvý</title>
<path d="M7 18V6H9V18H7ZM11 12 17 6V18L11 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const BackIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Predchádzajúci</title>
<path d="M8 12L14 6V18L8 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const ForwardIcon = () => {return<svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Nasledujúci</title>
<path d="M16 12L10 18V6L16 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const ForwardToLastIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Posledný</title>
<path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const FavoriteIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Pridať do Obľúbených</title>
<path d="M4 2H20C20.5523 2 21 2.44772 21 3V22.2763C21 22.5525 20.7761 22.7764 20.5 22.7764C20.4298 22.7764 20.3604 22.7615 20.2963 22.7329L12 19.0313L3.70373 22.7329C3.45155 22.8455 3.15591 22.7322 3.04339 22.4801C3.01478 22.4159 3 22.3465 3 22.2763V3C3 2.44772 3.44772 2 4 2ZM12 13.5L14.9389 15.0451L14.3776 11.7725L16.7553 9.45492L13.4695 8.97746L12 6L10.5305 8.97746L7.24472 9.45492L9.62236 11.7725L9.06107 15.0451L12 13.5Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const PriceIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<path d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15ZM12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const CalendarIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
width="24" height="24"
viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line>
<title id="title">Kalendár</title>
</svg>
}

export const LinkIcon = () =>{
    return <svg 
    class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" 
    viewBox="0 0 24 24">
    <path stroke="blue" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
  </svg>
}

export const PaperClipIcon = () =>{
  return <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="24" height="24" 
  viewBox="0 0 24 24" fill="none" stroke="#009BCE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
    </svg>
}

export const ProblemIcon = ({color}) =>{
  switch (color){
    case 'red': return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#ff0000" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'green': return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#037d50" stroke="#037d50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'blue': return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#0000ff" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'orange': return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#ffa500" stroke="#ffa500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'magenta': return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#ff00ff" stroke="#ff00ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    default: return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
  }
}

export const EditRowIcon = () =>{
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><path d="M12 8l4 4-4 4M8 12h7"/></svg>;
}

export const InactiveCopyIcon = () => {
  return <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a2 2 0 0 0-1 .3V5c0-1.1.9-2 2-2h7Z" clip-rule="evenodd"/>
  <path fill-rule="evenodd" d="M8 7v4H4.2c0-.2.2-.3.3-.4l2.4-2.9A2 2 0 0 1 8 7.1Zm2 0v4a2 2 0 0 1-2 2H4v6c0 1.1.9 2 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z" clip-rule="evenodd"/>
</svg>
}

export const ActiveCopyIcon = () => {
  return <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5 12 4.7 4.5 9.3-9"/>
</svg>
}

export const LoadPrioIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
  fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" >
  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"/></svg>
}

export const PenRulerIcon = () => {
return <svg 
xmlns="http://www.w3.org/2000/svg"
className="w-6 h-6 mr-2"
fill="none"
viewBox="0 0 24 24"
stroke="#7f1d1d"
strokeWidth={1.5}
 > <g> <path d="M5.636 12.707l1.828 1.829L8.88 13.12 7.05 11.293l1.414-1.414 1.829 1.828 1.414-1.414L9.88 8.464l1.414-1.414L13.12 8.88l1.415-1.415-1.829-1.828 2.829-2.828a1 1 0 0 1 1.414 0l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414l2.828-2.829zm8.485 5.656l4.243-4.242L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 5.636 9.878z"/> </g> </svg>
}

 export const paletteIcon = () => {
 return <svg xmlns="http://www.w3.org/2000/svg"
 className="w-6 h-6 text-gray-100"
 viewBox="0 0 24 24"
 stroke="#7f1d1d"
 fill="currentColor"> <path d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 .5.5v5.277l4.147-4.131a.5.5 0 0 1 .707 0l3.535 3.536a.5.5 0 0 1 0 .708L10.261 10H15.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H3a2.99 2.99 0 0 1-2.121-.879A2.99 2.99 0 0 1 0 13.044m6-.21 7.328-7.3-2.829-2.828L6 7.188v5.647zM4.5 13a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM15 15v-4H9.258l-4.015 4H15zM0 .5v12.495V.5z"/> </svg>;
 }

 export const fileTextIcon = () => { return <svg xmlns="http://www.w3.org/2000/svg"
  className="w-6 h-6 text-gray-100"
   viewBox="0 0 24 24"
   stroke="currentColor"
    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>;
}

export const settingsIcon = () => { return <svg xmlns="http://www.w3.org/2000/svg" 
    className="w-6 h-6 mr-2"
    viewBox="0 0 24 24" 
    fill="none"
    stroke="currentColor" 
    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>
}
export const BrushIcon = () => { return <svg xmlns="http://www.w3.org/2000/svg"
    className="w-8 h-8 -ml-2 mr-2 text-gray-100"
    viewBox="0 0 20 20"
    fill="none"
    stroke="#7f1d1d" >
    <path d="M18.5 5.5H5.5V12.5C5.5 13.6046 6.39543 14.5 7.5 14.5H10.5V18C10.5 18.8284 11.1716 19.5 12 19.5C12.8284 19.5 13.5 18.8284 13.5 18V14.5H16.5C17.6046 14.5 18.5 13.6046 18.5 12.5V5.5Z" stroke="#7f1d1d"/>
    <path d="M6 11.5H18" stroke="#7f1d1d" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 6V8" stroke="#7f1d1d" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 6V9.5" stroke="#7f1d1d" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 6V7" stroke="#7f1d1d" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export const TrainingIcon = () => { return <svg xmlns="http://www.w3.org/2000/svg" 
    width="24" height="24" 
    className="mr-2"
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="#7f1d1d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75">
    </path>
    </svg>
}

export const CalIcon = () => { return <svg xmlns="http://www.w3.org/2000/svg" 
    width="24" height="24" 
    className="mr-2"
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="#7f1d1d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
}

export const PrioIcon = () => { 
return <svg class="w-6 h-6 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
<path stroke="#7f1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.3 1.5L4 20h5M4 5l2-1v6m-2 0h4"/></svg>
}

export const PrioBack = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" 
  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7f1d1d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M19 12H6M12 5l-7 7 7 7"/></svg>
}

export const RefreshIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="#7f1d1d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2"/>
    <title id="title">Aktualizovať</title>
    </svg>;
}

export const CloseMess = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" 
  width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#696CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
}

export const PrioSort = () => {
return <svg class="w-6 h-6 text-gray-800" 
aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
fill="none" viewBox="0 0 24 24"><path stroke="#7f1d1d" 
strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.3 1.5L4 20h5M4 5l2-1v6m-2 0h4"/>   
</svg>}

export const CalendarSort = () => {return <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="24" height="24"
  viewBox="0 0 24 24" fill="none" stroke="#7f1d1d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line>
  <title id="title">Kalendár</title>
  </svg>
  }

  export const InviteDate = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <title id="title">Dátum</title>
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6">
      </line><line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
  }
  
  export const InviteTime = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <title id="title">Čas</title>
      <circle cx="12" cy="12" r="10"></circle>
      <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
  }
  
  export const InviteLocation = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <title id="title">Miesto</title>
      <path d="M12 22s-8-4.5-8-11.8A8 8 0 0 1 12 2a8 8 0 0 1 8 8.2c0 7.3-8 11.8-8 11.8z"/>
      <circle cx="12" cy="10" r="3"/>
      </svg>
  }
  
  export const TrainingTheme = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4">
    </circle>
    <title id="title">Názov školenia</title>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
    </svg>
  }