import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { SaveAndCloseIcon,SaveIcon, PrioBack, InviteDate, InviteTime, InviteLocation, TrainingTheme} from "../svg/svgIcons";
import { useFormik } from 'formik'; 
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';
import Quagga from 'quagga';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { DateTime } from "luxon";
import "../../assets/css/styles.css";

export default function TrainingInvitationAdd(invitation){

    const navigate = useNavigate();

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [saveAfterClose,setSaveAfterClose] = useState(false);

    const [showInvitationPreview,setShowInvitationPreview] = useState(false);
    const [procesedText,setProcesedText] = useState("");

    const [formInvitation,setFormInvitation] = useState(invitation);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState(state.training_types);

    const submitRef = useRef(null);
    const contentRef = useRef(null);
    

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };


    const formik = useFormik({
        initialValues: {
            name: '',invitation_date: '',invitation_time:'',location:'',training_id:'',training_company_id:'',contact_id:'',training_invitation_template_id:'',content:'',notifications:[],email:true,phone:false,employees:[],
        },
        enableReinitialize: true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám");
            setVisible(true);
            let formData = new FormData();
            for (let value in values) {
                if(value=="notifications"){
                    formData.append("notifications", values.notifications.map(condition => condition.name));
                }
                else if(value=="employees"){
                    formData.append("employees", values.employees.map(employee => employee.id));
                }
                else  formData.append(value, values[value] != null ? values[value] : "");
            }
            postRequest('/api/training_invitations', formData,true)
            .then((response) => {
                console.log(response);
                formik.resetForm();
                setShowInvitationPreview(false);
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                if(saveAfterClose)navigate("/traininghome");
                setVisible(false);
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
                setVisible(false);
            });
        },
    });

    function insertMyText(textToInsert){
        let cursorPosition = contentRef.current.selectionStart;
        console.log(contentRef.current.selectionStart);
        let textBeforeCursorPosition = contentRef.current.value.substring(0, cursorPosition);
        let textAfterCursorPosition = contentRef.current.value.substring(cursorPosition, contentRef.current.value.length);
        contentRef.current.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        contentRef.current.selectionStart = contentRef.current.selectionStart + textToInsert.length;
        contentRef.current.focus();
        console.log(contentRef.current.selectionStart,textToInsert.length);
        formik.setFieldValue("content", contentRef.current.value);
      }

      const dayTimePeriod = [
        {name: '1 deň pred'},
        {name: '2 dní pred'},
        {name: '3 dní pred'},
        {name: '4 dní pred'},
        {name: '5 dní pred'},
        {name: '6 dní pred'},
        {name: '7 dní pred'},
    ];

    function previewInvitation(){
        setShowInvitationPreview(true);
        setProcesedText(formik.values.content.replace("[Dátum]",DateTime.fromISO(formik.values.invitation_date).toFormat("dd.MM.yyyy")).replace("[Čas]",DateTime.fromISO(formik.values.invitation_time).toFormat("HH:MM")).replace("[Miesto]",formik.values.location).replace("[Názov školenia]",state.training_types.filter((training) => training.id == formik.values.training_id)[0]?.name));
    }

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        if(departmentId=="")setTrainings(state.training_types);
        setTrainings(state.training_types
        .filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    function changeTemplate(template_id){
        formik.setFieldValue('training_invitation_template_id',template_id);
        if(template_id){
            let my_template = state.training_invitation_templates.filter((inv_template) => inv_template.id == template_id)[0];
            if(my_template){
                formik.setFieldValue('content',my_template?.content);
                contentRef.current.value = my_template.content;
                contentRef?.current?.focus();
            }
        }
        else{
            formik.setFieldValue('content',"");
            contentRef.current.value = "";
            contentRef?.current?.focus();
        }
    }

    return(
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col pb-2 bg-white min-h-[calc(100vh_-_56px)]'>
        <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/traininghome")}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAfterClose(true);submitRef.current.click()}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        </div>
            <div className='w-auto px-4 mx-2'>
                <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>NOVÁ POZVÁNKA</h1>
                <div className='w-full mb-2'>
                    <label htmlFor='name' className='font-semibold'>
                        Názov:
                        <input required
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='name'
                        onChange={formik.handleChange} value={formik.values.name}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='invitation_date' className='font-semibold'>
                        Dátum:
                        <input required
                        className='w-full min-h-10 text-left p-2 border border-black rounded-md [appearance:none]'
                        type='date'
                        name='invitation_date'
                        onChange={formik.handleChange} value={formik.values.invitation_date}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='invitation_time' className='font-semibold'>
                        Čas:
                        <input required
                        className='w-full min-h-10 text-left p-2 border border-black rounded-md [appearance:none]'
                        type='time'
                        name='invitation_time'
                        onChange={formik.handleChange} value={formik.values.invitation_time}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='location' className='font-semibold'>
                        Miesto:
                        <input required
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='location'
                        onChange={formik.handleChange} value={formik.values.location}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='trainingDepartment' className='font-semibold '>
                        Oddelenie školenia:
                        <select className='w-full h-10 border border-black rounded-md text-black'
                        name="trainingDepartment"
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        >
                            <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='training_id' className='font-semibold '>
                        Názov školenia:
                        <select required
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="training_id"
                        onChange={formik.handleChange} value={formik.values.training_id}
                        >
                            <option value="">Vyber školenie</option>
                          {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='training_company_id' className='font-semibold '>
                        Školiaca firma:
                        <select 
                        className='w-full h-10 border border-black rounded-md text-black'
                        name='training_company_id'
                        onChange={formik.handleChange} value={formik.values.training_company_id}
                        >
                            <option value="">Vyber škol. firmu</option>
                          {state.training_companies.map((training_company) => (
                            <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='contact_id' className='font-semibold '>
                        Školiteľ:
                        <select
                        className='w-full h-10 border border-black rounded-md text-black'
                        name='contact_id'
                        onChange={formik.handleChange} value={formik.values.contact_id}
                        >
                             <option value="">Vyber školiteľa</option>
                          {state.training_company_contacts.filter(contact => contact.training_company_id == formik.values?.training_company_id).map((contact) => (
                            <option key={contact.id} value={contact.id}>{contact.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor="employees" className='font-semibold'>
                        Zamestnanci
                        <MultiSelect required name='employees' value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.employees.map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name"
                        filter placeholder="Vyber zamestnancov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='training_invitation_template_id' className='font-semibold '>
                        Vzor pozvánky:
                        <select
                        className='w-full h-10 border border-black rounded-md text-black'
                        name='training_invitation_template_id'
                        onChange={(e) => changeTemplate(e.target.value)} value={formik.values.training_invitation_template_id}
                        >
                            <option value="">Bez vzoru</option>
                          {state.training_invitation_templates.map((template) => (
                            <option key={template.id} value={template.id}>{template.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='training_invitation_template_id' className='font-semibold '>
                        Obsah pozvánky:
                        <div className='w-full h-12 flex flex-row justify-start gap-1 px-1 py-1 text-white'>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Dátum]")}><InviteDate/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Čas]")}><InviteTime/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Miesto]")}><InviteLocation/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Názov školenia]")}><TrainingTheme/></button></div>
                        </div>
                        <InputTextarea ref={contentRef} autoResize name='content' required onChange={formik.handleChange} value={formik.values.content} rows={5} cols={30} className="w-full p-2 border border-black rounded-md"/>
                    </label>
                </div>
                <div className='w-full mb-4'>
                    <label htmlFor='notifications' className='font-semibold'>
                        Odoslanie upozornenia:
                        <MultiSelect required value={formik.values.notifications} onChange={(e) => formik.setFieldValue("notifications",e.value)} options={dayTimePeriod} optionLabel="name"
                        filter placeholder="Vyber pravidelnosť" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                    </label>
                </div>
                <div className='flex flex-col'>
                    <div className="flex justify-content-center mb-2">
                        <label htmlFor='email' className='font-semibold mr-2'>Email:</label>
                        <InputSwitch name='email' checked={formik.values.email} onChange={(e) => formik.setFieldValue("email",e.value)} />
                    </div>
                </div>
                <div className='w-full mb-2'>
                    <button type='button' onClick={() => previewInvitation()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Zobraziť náhľad</button>
                </div>
            </div>
            <div className='w-auto px-4 mx-2'>
            {showInvitationPreview && <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <label className='block text-md font-semibold mr-2'>Náhľad pozvánky:</label>
                <InputTextarea autoResize name='content' required value={procesedText} rows={5} cols={30} className="w-full p-2 border border-black rounded-md"/>
            </div>}
            </div>
        </div>
        <button type="submit" ref={submitRef} className='hidden'></button>
        </form>
        </>
    )
}