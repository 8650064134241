import React from 'react';
import Navbar from './components/Navbar';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './HomePage';
import PriorityList from './pages/Priorities/PriorityList';
import OTKHome from './pages/OTK/OTKHome';
import OTKMeasure from './pages/OTK/OTKMeasure';
import OTKSearch from './pages/OTK/OTKSearch';
import PaintingHome from './pages/Painting/PaintingHome';
import PaintingMeasure from './pages/Painting/PaintingMeasure';
import PaintingSearch from './pages/Painting/PaintingSearch';
import CalibrationHome from './pages/Calibration/CalibrationHome';
import CalibrationMeasure from './pages/Calibration/GaugeAdd';
import CalibrationSearch from './pages/Calibration/CalibrationSearch';
import TrainingHome from './pages/Training/TrainingHome';
import TrainingRecordAdd from './pages/Training/TrainingRecordAdd';
import TrainingInvitationAdd from './pages/Training/TrainingInvitationAdd';
import Login from './pages/Auth/Login';
import { AuthContextProvider } from './components/AuthContext';
import DatabaseStore from './redux/DatabaseStore';
import ProtectedRoute from './components/ProtectedRoute';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-blue/theme.css'; // core css
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';                      
import 'react-tabulator/css/tabulator_bootstrap5.css';
import './assets/css/styles.css';
import './assets/css/priorities.css';
import { UtilContextProvider } from './components/UtilContext';

function App() {
  return (
    <>
    <BrowserRouter>
    <PrimeReactProvider>
      <AuthContextProvider>
        <DatabaseStore>
        <UtilContextProvider>
          <Routes>
            <Route id='1' path="/" element={<ProtectedRoute accessBy="authenticated"> <HomePage/> </ProtectedRoute>} />
            <Route path="/priorities" element={<ProtectedRoute accessBy="authenticated"> <PriorityList/> </ProtectedRoute>} />
            <Route path="/otkhome" element={<ProtectedRoute accessBy="authenticated"><OTKHome /></ProtectedRoute>} />
            <Route path="/otkmeasure" element={<ProtectedRoute accessBy="authenticated"><OTKMeasure /></ProtectedRoute>} />
            <Route path="/otksearch" element={<ProtectedRoute accessBy="authenticated"><OTKSearch /></ProtectedRoute>} />
            <Route path="/paintinghome" element={<ProtectedRoute accessBy="authenticated"><PaintingHome /></ProtectedRoute>} />
            <Route path="/paintingmeasure" element={<ProtectedRoute accessBy="authenticated"><PaintingMeasure /></ProtectedRoute>} />
            <Route path="/paintingsearch" element={<ProtectedRoute accessBy="authenticated"><PaintingSearch /></ProtectedRoute>} />
            <Route path="/calibrationhome" element={<ProtectedRoute accessBy="authenticated"><CalibrationHome /></ProtectedRoute>} />
            <Route path="/calibrationmeasure" element={<ProtectedRoute accessBy="authenticated"><CalibrationMeasure /></ProtectedRoute>} />
            <Route path="/calibrationsearch" element={<ProtectedRoute accessBy="authenticated"><CalibrationSearch /></ProtectedRoute>} />
            <Route path="/login" element={<ProtectedRoute accessBy="non-authenticated"><Login /></ProtectedRoute>} />
            <Route path="/traininghome" element={<ProtectedRoute accessBy="authenticated"><TrainingHome /></ProtectedRoute>} />
            <Route path="/trainingrecordadd" element={<ProtectedRoute accessBy="authenticated"><TrainingRecordAdd /></ProtectedRoute>} />
            <Route path="/traininginvitationadd" element={<ProtectedRoute accessBy="authenticated"><TrainingInvitationAdd /></ProtectedRoute>} />
          </Routes>
          </UtilContextProvider>
        </DatabaseStore>
      </AuthContextProvider>
      </PrimeReactProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
